// share.js
const share = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.store('share', {
            async trigger(title, url) {
                if (navigator.share) {
                    try {
                        await navigator.share({
                            title: title,
                            url: url
                        });
                        console.log('Successful share');
                    } catch (error) {
                        console.error('Error sharing:', error);
                    }
                }
            },
            async copyToClipboard($element, text) {
                try {
                    await navigator.clipboard.writeText(text);
                    $element.textContent = 'Copied!';
                    setTimeout(() => {
                        $element.textContent = 'Copy';
                    }, 2000);
                    console.log('Copied to clipboard');
                } catch (error) {
                    console.error('Error copying to clipboard:', error);
                }
            }
            });
        });
    }
};

export default share;