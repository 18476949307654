import localeEn from 'air-datepicker/locale/en';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

const datePicker = {
    init(element) {
      if (!element) return;
      new AirDatepicker(element, {
        range: true,
        multipleDatesSeparator: ' - ',
        autoClose: true,
        locale: localeEn,
        buttons: ['clear'],
        onSelect: function(event) {
          if (event.date.length === 2) {
            console.log('Selected date range: ', event.date[0], event.date[1]);
            // simulated a field change event to trigger htmx form submission
            element.dispatchEvent(new Event('change', { bubbles: true }));
          } else {
            console.log('First date selected: ', event.date[0]);
            if (typeof event.date[0] === 'undefined') {
              // this means "clear" the date input field
              // simulated a field change event to trigger htmx form submission
              element.dispatchEvent(new Event('change', { bubbles: true }));
              event.datepicker.hide()
            }
          }
        }
      });
    }
};

// Attach the entire object to window, not just the init function
window.datePicker = datePicker;

export default datePicker;