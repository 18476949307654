// addToCalendar.js

const addToCalendar = {
    async addToCalendar(id, type, event) {
      try {
        const response = await fetch('/actions/events-helper-module/events-helper/index', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: `id=${id}&type=${type}&event=${event}`
        });
        const data = await response.text();
        const result = JSON.parse(data);
        console.log(result);
        if (!result.error) {
          const a = document.createElement('a');
          a.href = result.url;
          a.download = result.filename;
          a.click();
          setTimeout(() => this.removeFile(result.file), 10000);
        }
      } catch (error) {
        console.error('Error adding to calendar:', error);
      }
    },

    async removeFile(file) {
      try {
        await fetch('actions/events-helper-module/events-helper/remove-file', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: `file=${file}`
        });
        console.log('file removed');
      } catch (error) {
        console.error('Error removing file:', error);
      }
    },

    init() {
      document.addEventListener('alpine:init', () => {
        Alpine.store('calendar', {
          addToCalendar: this.addToCalendar.bind(this),
          removeFile: this.removeFile.bind(this)
        });
      });
    }
};

export default addToCalendar;