/* Universal javascript */
import '/src/css/app.css';
import Alpine from 'alpinejs'
import jsResizeAnimationStopper from './utils/resizeAnimationStopper.js'
import swiper from './components/swiper.js'
import lightbox from './components/lightbox.js'
import htmxEvents from './components/htmxEvents.js'
import datePicker from './components/datePicker.js'
import addToCalendar from './components/addToCalendar.js'
import share from './components/share.js'

// Get any third-party libraries
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;
lazySizes.init();
jsResizeAnimationStopper.init();
swiper.init();

window.Alpine = Alpine;

htmxEvents.init();
datePicker.init();
addToCalendar.init();
share.init();

lightbox.init();
Alpine.start()