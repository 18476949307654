// htmxEvents.js
import swiper from './swiper.js'

const htmxEvents = {
    init() {
        if (typeof htmx !== 'undefined') {
            this.setupScrollBehavior();
            this.setupLoadingStates();
        }
    },

    setupScrollBehavior() {
        htmx.on('htmx:afterSwap', function(event) {

            const firstElementChild = event?.detail?.target.firstElementChild;

            if (firstElementChild && firstElementChild?.classList.contains('--scroll-to-top')) {
                const top = event?.detail?.target?.getBoundingClientRect()?.top;

                window.scrollTo({top: (top || 0), behavior: 'smooth'});
            } else {
                const scrollTopTopElement = document.querySelector('.--scroll-to-top');

                if (scrollTopTopElement) {
                    scrollTopTopElement.scrollIntoView({behavior: 'smooth'});
                }
            }
            swiper.init();
        });
    },

    setupLoadingStates() {
        htmx.on('htmx:beforeRequest', function(event) {
            event.detail.target.classList.add('is-loading');
        });

        htmx.on('htmx:afterRequest', function(event) {
            event.detail.target.classList.remove('is-loading');
        });
    }
};

export default htmxEvents;